export default {
  type: 'Timed',
  options: [
    {
      duration: 230 * 60 * 1000,
      hms: '3h 50m 0s',
      questionsToAnswer: 180
    },
    {
      duration: 3 * 60 * 60 * 1000,
      hms: '3h 0m 0s',
      questionsToAnswer: 141
    },
    {
      duration: 2.5 * 60 * 60 * 1000,
      hms: '2h 30m 0s',
      questionsToAnswer: 118
    },
    {
      duration: 2 * 60 * 60 * 1000,
      hms: '2h 0m 0s',
      questionsToAnswer: 95
    },
    {
      duration: 1.5 * 60 * 60 * 1000,
      hms: '1h 30m 0s',
      questionsToAnswer: 71
    },
    {
      duration: 1 * 60 * 60 * 1000,
      hms: '1h 0m 0s',
      questionsToAnswer: 48
    },
    {
      duration: 0.5 * 60 * 60 * 1000,
      hms: '0h 30m 0s',
      questionsToAnswer: 24
    }
  ]
}

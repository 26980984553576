import React, { Component } from 'react'
import classes from './Selector.module.css'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

class Selector extends Component {
  state = {
    open: false
  }

  handleOpen = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  handleOnChange = e => {
    this.setState({
      value: e.target.value
    })
  }

  render() {
    const menuItems = this.props.options.map(o => (
      <MenuItem key={o} value={o}>
        {o}
      </MenuItem>
    ))

    return (
      <div className={classes.Main}>
        <FormControl disabled={this.props.isActiveExam ? true : false}>
          <InputLabel id="timeSelectorLabel" className={classes.Label}>
            Duration
          </InputLabel>
          <Select
            labelId="timeSelector"
            id="timeSelector"
            value={this.props.value}
            open={this.state.open}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            onChange={this.props.change}
          >
            {menuItems}
          </Select>
        </FormControl>
      </div>
    )
  }
}

export default Selector

import React, { Component } from 'react'
import classes from './Timer.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import TextField from '@material-ui/core/TextField'

const hms = distance => {
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  return `${hours}h ${minutes}m ${seconds}s`
}

class Timer extends Component {
  timer = null

  state = {
    timeleft: 'h m s'
  }

  componentDidMount() {
    if (!this.timer) {
      let timer = setInterval(() => {
        const deadline =
          this.props.acceleration === 0
            ? this.props.deadline
            : this.props.acceleratedDeadline
        const distance = deadline - Date.now()
        const timeLeft = hms(distance)

        this.setState({ timeleft: timeLeft })

        if (distance <= 0) {
          clearInterval(this.timer)
          this.timer = null

          // Don't send exam results to DB if there were no answers
          if (this.props.result.length !== 0) {
            const result = {
              date: Date.now(),
              duration: this.props.duration,
              questionsToAnswer: this.props.qta,
              questionsAnswered: this.props.qa,
              result: this.props.result
            }

            this.props.onExamFinish(this.props.userId, this.props.token, result)
          }

          this.props.onExamStop()
        }
      }, 10)

      this.timer = timer
    }
  }

  // REFACTOR
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render() {
    return (
      <TextField
        id="timedExamTimer"
        label="Time Left"
        InputProps={{
          readOnly: true
        }}
        value={this.state.timeleft}
        className={classes.Main}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    deadline: state.exam.deadline,
    isActiveExam: state.exam.exam !== null,
    userId: state.auth.userId,
    token: state.auth.token,
    duration: state.exam.duration,
    qta: state.exam.questionsToAnswer,
    qa: state.exam.questionsAnswered,
    result: state.exam.result,
    acceleration: state.exam.acceleration,
    acceleratedDeadline: state.exam.acceleratedDeadline
  }
}

const mapActionToProps = dispatch => {
  return {
    onExamFinish: (userId, token, result) =>
      dispatch(actions.resultSave(userId, token, result)),
    onExamStop: () => dispatch(actions.examStop())
  }
}

export default connect(mapStateToProps, mapActionToProps)(Timer)

import React from 'react'
import classes from './ConfirmStart.module.css'
import Backdrop from '../../../UI/Backdrop/Backdrop'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import closeIcon from '../../../../images/icons/closeOutlined.svg'

export default props => (
  <Backdrop show={true} clicked={props.onClose}>
    <div className={classes.Alert}>
      <Paper elevation={5} className={classes.Message}>
        <div
          className={classes.Icon}
          onClick={props.onClose}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <img src={closeIcon} alt="close icon" />
        </div>
        In order to save your Mock Exam result do not close your browser or
        current tab until you complete the Exam. Also make sure to have internet
        connection for the duration of Mock Exam. Best of luck!
      </Paper>
      <Button
        variant="contained"
        onClick={props.onStart}
        style={{
          backgroundColor: '#5fba7d',
          color: '1C132A',
          fontWeight: '550',
          lineHeight: 'unset',
          width: '100%',
          height: '48px',
          marginTop: '15px'
        }}
      >
        start mock exam
      </Button>
    </div>
  </Backdrop>
)

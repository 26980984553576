import React from 'react'
import classes from './Alert.module.css'
import Alert from '@material-ui/lab/Alert'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'

const alert = props => {
  let al = null

  if (props.examSuccess) {
    al = (
      <Link to="/pmp-exam-simulator-statistics">
        <Alert
          severity="success"
          className={classes.Main}
          onClose={e => {
            e.preventDefault()

            props.onMessageClose()
          }}
        >
          Congrats!!! Click to see the statistics.
        </Alert>
      </Link>
    )
  }

  return al
}

const mapStateToProps = state => {
  return {
    examSuccess: state.exam.examSuccess
  }
}

const mapActionToProps = dispatch => {
  return {
    onMessageClose: () => dispatch(actions.examEraseSuccessMessage())
  }
}

export default connect(mapStateToProps, mapActionToProps)(alert)

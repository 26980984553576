import React, { Component } from 'react'
import classes from './TimedExam.module.css'
import { connect } from 'react-redux'
import timedExam from '../../../utils/exam/timed'
import * as actions from '../../../store/actions/index'
import Progress from './Progress/Progress'
import Timer from './Timer/Timer'
import Button from './Button/Button'
import Selector from './Selector/Selector'
import AlarmIcon from '../../../images/icons/alarm.svg'
import { navigate } from 'gatsby'
import Switch from '../../UI/Switch/Switch'
import settings from '../../../utils/exam/settings'
import Info from './Info/Info'
import ConfirmStart from './ConfirmStart/ConfirmStart'

class TimedExam extends Component {
  state = {
    duration: this.props.isActiveExam
      ? this.props.duration
      : timedExam.options[0].hms,
    durationPure: timedExam.options[0].duration,
    qta: this.props.isActiveExam
      ? this.props.qta
      : timedExam.options[0].questionsToAnswer,
    displayConfirmStart: false
  }

  handleStartStop = () => {
    if (this.props.redirect && !this.props.isActiveExam) {
      navigate(this.props.redirect)
    } else {
      if (this.props.isActiveExam) {
        this.props.onTimedExamStop()
      } else {
        if (this.props.examSuccessMessage) this.props.onMessageErase()

        this.setState({
          displayConfirmStart: true
        })
      }
    }
  }

  handleStart = () => {
    let deadline =
      Date.now() +
      timedExam.options.filter(o => o.hms === this.state.duration)[0].duration

    this.props.onTimedExamStart(
      this.state.duration,
      this.state.duration,
      deadline,
      this.state.qta
    )

    this.setState({
      displayConfirmStart: false
    })

    if (this.props.isFieldLoked) this.props.onFieldRelease()
  }

  handleCloseConfirmStart = () => {
    this.setState({
      displayConfirmStart: false
    })
  }

  handleOnChange = e => {
    const option = timedExam.options.filter(o => o.hms === e.target.value)[0]

    const qta = option.questionsToAnswer
    const durationPure = option.duration

    this.setState({
      duration: e.target.value,
      qta: qta,
      durationPure: durationPure
    })
  }

  handleSwitchDisplayCorrectAnswer = () => {
    this.props.onDisplayCorrectAnswer()
  }

  handleSwitchAcceleration = () => {
    if (this.props.acceleration !== settings.acceleration)
      this.props.onAccelerateExam(settings.acceleration)
    else this.props.onAccelerateExam(0)
  }

  render() {
    const progress = <Progress qta={this.state.qta} />
    let deadline = null

    if (this.props.isActiveExam) {
      deadline = <Timer />
    }

    return (
      <div>
        <div className={classes.Main}>
          <div className={classes.Icon}>
            <img src={AlarmIcon} alt="alarm icon" />
          </div>
          <Selector
            options={timedExam.options.map(o => o.hms)}
            value={this.state.duration}
            change={this.handleOnChange}
            isActiveExam={this.props.isActiveExam}
          />
          {progress}
          {deadline}
          <Button
            isActiveExam={this.props.isActiveExam}
            isAuthenticated={this.props.isAuthenticated}
            click={this.handleStartStop}
            disabled={this.props.paidAccessUntil < Date.now()}
          />
        </div>
        <div className={classes.Settings}>
          <Switch
            onSwitch={this.handleSwitchAcceleration}
            checked={this.props.acceleration !== 0}
            label="Accelerate Mock Exam"
            disabled={
              this.props.isActiveExam === true
                ? this.props.acceleratedDeadline - Date.now() <= 60 * 1000
                  ? true
                  : false
                : true
            }
          />
          <Switch
            onSwitch={this.handleSwitchDisplayCorrectAnswer}
            checked={this.props.displayCorrectAnswer}
            label="Display correct answer"
            disabled={!this.props.isActiveExam}
          />
        </div>
        <Info
          text="PMP exam lasts 230min and has 180 questions"
          tooltip="Even though PMP Exam lasts 230min, our simulator has other options for your convenience. All duration options run at the pace of the actual PMP Exam."
        />
        <Info
          text="Use acceleration to have extra 30m in reserve on exam"
          tooltip="In order to pass, you should be able to answer 180 questions in 3.5h at most. Mock Exam acceleration speeds up time for that purpose. Practicing with acceleration will give you extra time on exam to review some questions or at least to use the restroom or have a snack"
        />
        {this.state.displayConfirmStart ? (
          <ConfirmStart
            onStart={this.handleStart}
            onClose={this.handleCloseConfirmStart}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    isActiveExam: state.exam.exam !== null,
    duration: state.exam.duration,
    qta: state.exam.questionsToAnswer,
    examSuccessMessage: state.exam.examSuccess,
    isFieldLoked: state.field.locked !== null,
    paidAccessUntil: state.user.paidAccessUntil,
    displayCorrectAnswer: state.exam.displayCorrectAnswer,
    acceleration: state.exam.acceleration,
    acceleratedDeadline: state.exam.acceleratedDeadline
  }
}

const mapActionToProps = dispatch => {
  return {
    onTimedExamStart: (type, duration, deadline, questionsToAnswer) =>
      dispatch(actions.examStart(type, duration, deadline, questionsToAnswer)),
    onTimedExamStop: () => dispatch(actions.examStop()),
    onMessageErase: () => dispatch(actions.examEraseSuccessMessage()),
    onFieldRelease: () => dispatch(actions.fieldRelease()),
    onDisplayCorrectAnswer: () => dispatch(actions.examDisplayCorrectAnswer()),
    onAccelerateExam: acceleration =>
      dispatch(actions.examAccelerationChanged(acceleration))
  }
}

export default connect(mapStateToProps, mapActionToProps)(TimedExam)

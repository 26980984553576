import React from 'react'
import classes from './Info.module.css'
import InfoIcon from '../../../../images/icons/info.svg'
import Tooltip from '../../../UI/Tooltip/Tooltip'

export default props => (
  <Tooltip title={props.tooltip} placement="bottom-start">
    <div className={classes.Info}>
      <img src={InfoIcon} className={classes.InfoIcon} alt="info icon" />
      <span>{props.text}</span>
    </div>
  </Tooltip>
)

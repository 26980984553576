import React from 'react'
import classes from './Progress.module.css'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import TextField from '@material-ui/core/TextField'

const Progress = props => {
  if (props.qa === props.qtaState) {
    const result = {
      date: Date.now(),
      duration: props.duration,
      questionsToAnswer: props.qtaState,
      questionsAnswered: props.qa,
      result: props.result
    }

    props.onExamFinish(props.userId, props.token, result)
    props.onExamStop()
  }

  return (
    <TextField
      id="timedExamProgress"
      label="Questions"
      InputProps={{
        readOnly: true
      }}
      className={classes.Progress}
      value={
        props.qa + '/' + (props.qtaState !== null ? props.qtaState : props.qta)
      }
    />
  )
}

const mapActionToProps = dispatch => {
  return {
    onExamFinish: (userId, token, result) =>
      dispatch(actions.resultSave(userId, token, result)),
    onExamStop: () => dispatch(actions.examStop())
  }
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    duration: state.exam.duration,
    qtaState: state.exam.questionsToAnswer,
    qa: state.exam.questionsAnswered,
    result: state.exam.result
  }
}

export default connect(mapStateToProps, mapActionToProps)(Progress)

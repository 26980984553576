import React from 'react'
import classes from './Button.module.css'
import Button from '@material-ui/core/Button'
import Tooltip from '../../../UI/Tooltip/Tooltip'

export default props => (
  <Tooltip
    title={
      props.isAuthenticated
        ? props.disabled
          ? 'Replenish paid access'
          : ''
        : 'Sign in to start Exam Simulator'
    }
  >
    <div className={classes.Main}>
      <Button
        variant="contained"
        onClick={props.click}
        style={{
          backgroundColor: props.isActiveExam ? '#f50057' : '#5fba7d',
          color: '1C132A',
          fontWeight: '550',
          lineHeight: 'unset'
        }}
        disabled={props.disabled}
      >
        {props.isActiveExam ? 'Stop' : 'Start'}
      </Button>
    </div>
  </Tooltip>
)

import React, { Component } from 'react'
import classes from './Examinator.module.css'
import TimedExam from './TimedExam/TimedExam'
import Alert from './TimedExam/Alert/Alert'
import Message from './TimedExam/Message/Message'

class Examinator extends Component {
  state = {
    showMessage: true
  }

  handleHideMessage = () => {
    this.setState({
      showMessage: false
    })
  }

  render() {
    return (
      <div className={classes.Examinator}>
        <Alert />
        <Message
          show={this.state.showMessage}
          hide={this.handleHideMessage}
          display={() => {
            this.handleDisplayMessage()
          }}
        />
        <center>
          <h1>PMP Exam Simulator</h1>
        </center>
        <TimedExam redirect={this.props.redirect} />
      </div>
    )
  }
}

export default Examinator

import React from 'react'
import classes from './Message.module.css'
import Backdrop from '../../../UI/Backdrop/Backdrop'
import { Link } from 'gatsby'
import Alert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'
import * as actions from '../../../../store/actions/index'
import Spinner from '../../../UI/Spinner/Spinner'

const message = props => {
  let mess = null

  if (props.examSaving) {
    mess = (
      <Backdrop show={true} className={classes.Message}>
        <div className={classes.Alert}>
          <Spinner />
        </div>
      </Backdrop>
    )
  }

  if (props.examSuccess) {
    mess = (
      <Backdrop
        show={props.show}
        className={classes.Message}
        clicked={props.hide}
      >
        <Link to="/pmp-exam-simulator-statistics">
          <Alert
            severity="success"
            className={classes.Alert}
            onClose={e => {
              e.preventDefault()

              props.onMessageClose()
            }}
          >
            Hooray!!! You successfully completed Mock Exam! Click to see the
            statistics.
          </Alert>
        </Link>
      </Backdrop>
    )
  }

  return mess
}

const mapStateToProps = state => {
  return {
    examSuccess: state.exam.examSuccess,
    examSaving: state.exam.saving
  }
}

const mapActionToProps = dispatch => {
  return {
    onMessageClose: () => dispatch(actions.examEraseSuccessMessage())
  }
}

export default connect(mapStateToProps, mapActionToProps)(message)
